import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "NewbieRewards",

  setup() {
    const router = useRouter(); // 返回上一页

    const onClickLeft = () => {
      router.back();
    }; // 模拟接收奖励的函数


    const receiveBonus = () => {
      console.log("Bonus received!");
    }; // 任务列表数据


    const tasks = ref([{
      id: 1,
      title: "Set a PIN",
      image: "https://cwmedia.qixin.in/uuuu/89609ca5d769483eaf9c90582e57076c.png"
    }, {
      id: 2,
      title: "Bind telegram account to receive notifications easily",
      image: "https://cwmedia.qixin.in/uuuu/7937ce1942174db1ae96652b3391ba20.png"
    }, {
      id: 3,
      title: "Add KYC UPI payment collection tool",
      image: "https://cwmedia.qixin.in/uuuu/22550a63e21647f989b1ec1d3c7d3a12.png"
    }, {
      id: 4,
      title: "Complete A Deposit",
      image: "https://cwmedia.qixin.in/uuuu/3a3a74ebfcc643c88654f681fc1c6bec.png"
    }]);
    return {
      onClickLeft,
      receiveBonus,
      tasks
    };
  }

};